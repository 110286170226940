body {
  
    background-position: center;
    background-size: cover;
    position: relative;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
  }
  
  .forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .forgot-password-box {
    background-color: #141A31;
    padding: 30px;
    border-radius: 6px;
    width: 400px;
    max-width: 80%;
   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    color: #fff;
  }
  
 .for-pass{
   font-size: 1px;
  
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .label-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
  }
  
  .label-input-container .input {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    width: 100%;
  }
  
  .error {
    color: #ff0000;
    font-size: 14px;
    margin-right: 39%;
    font-weight: 600;
    padding-bottom: 5px;
  }
  
  .submit-btn {
    padding: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    // padding-left: 45px;
    margin-top: 5%;
    text-align: center;
  }
  
  
  
  
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
    margin-top: 20px; 
  }
  
  .co-logo {
    margin-top: 50px;
  }
  
  .aa2 {
    text-align: center;
    padding: 4px 0;
    font-size: 17px;
    background: #fff;
    font-weight: 700;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    border-top: 1px solid #dedede;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    z-index: 10;
  }
  