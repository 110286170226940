
.order-management-container {
  position: fixed;
  margin-top: 8%;
  margin-bottom: 15%;
  margin-left: 10%;
  overflow: scroll;
  max-height: 600px;
  overflow-x: hidden;
  width:1200px;
  
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #141A31;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.order-table thead th {
  background-color: #141A31;
  border-bottom: 2px solid #f0f0f0;
  padding: 10px;
  text-align: left;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.order-table tbody td {
  padding: 10px;
  text-align: left;
  color: white;
  font-family: "Chakra Petch", sans-serif;
}

.view-images-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding:5px;
  cursor: pointer;
}

.email-order{
  text-transform: lowercase;
}

@media (max-width: 768px) {
  .order-management-container {
    width: 100%;
    margin-left: 0;
    top:10%;
  }

  .order-table {
    width: 100%;
    overflow: scroll;
  }

  .order-table thead th,
  .order-table tbody td {
    display: block;
  }

  .order-table thead th {
    float: left;
  }

  .order-table tbody td {
    width: 100%;
  }
}


