.image-upload-container {
    width: 600px;
    padding: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #141A31;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top:13%;
    margin-bottom: 15%; 
    padding-bottom: 90px;
    
}

  .input {
    width: 100%;
    padding: 8px;
    border: none;
    margin-bottom: 10px;
    font-size: 16px;
    border-radius: 5px;
} 

  .add-file {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 82%;
    padding-left: 2%;
    padding-right: 2%;
    width:40%;

  }

  .input-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .input-item {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 4px 12px;
    margin: 4px;
    display: flex;
    align-items: center;
  }

  .Remove-file {
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 25%;
    font-size: 12px;
    text-align: center;
   
    padding-left: 8px;
    padding-right: 8px;
    padding-top:1px ;
    padding-bottom:1px ;
    margin-left: 6px;
  }

  .file-upload {
    text-align: center;
    margin: 20px 0;
    margin-top:20px;
    margin-right:200px;
    cursor: progress;
  }

  .up-load {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    padding-left: 235px;
    padding-right: 235px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 260px;
    margin-top: 10px;
    position: absolute;
    top:85%;
    left:6%;
    text-align: center;
  }

  .image-preview {
    text-align: center;
    margin-top: 20px;
  }

  .preview-image {
    width: 300px;
    height: 200px;
    border: 2px solid #0bb182;
    border-radius: 5px;
    padding: 10px;
  }

  .description {
    text-align: center;
    margin: 10px 0;
  }

  .field {
    text-align: center;
    margin: 10px 0;
  }

  @media screen and (max-width: 768px) {
    .image-upload-container {
      width: 90%;
      margin-top: 10%;
      margin-bottom: 8%;
      position: absolute;
      margin-top: 36%;
      margin-bottom: 15%;
    }

    .add-file {
      margin-right: 78%;
      width:40%;
      padding: 0;
    }

    .up-load {
     
        margin-left: 10px; 
        width: 80%; 
        padding-left: 1px; 
        padding-right: 1px; 
        margin-bottom:5%;
    
    }
  }
