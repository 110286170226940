.shopping {
  height: 300px;
  // width: 200px;
  margin-top: 1%;
  margin-left: 5%;
  border:2px solid rgba(11, 177, 130, 0.5);
  padding: 10px;
  width:70%;
  margin-bottom:5%;
}

.shop-cart {
  margin-top: 10%;
  
}

.download-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
}

.popup-content {
  background-color: #0c1226;
  padding: 25px;
  padding-top:10px;
  padding-bottom:60px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width:30%;

}

.down-load {
  margin-top: 1%;
  margin-left: 42%;
  margin-bottom:1%;
  padding: 0.7%;
  width: 20%;
  border: none;
  border-radius: 5px;
  background-color: #05956ad7;
  color: white;
  text-align: center;
}

.input-field {
  // background-color: rgb(236, 227, 227);
  margin-bottom: 10px; 
  border-radius: 5px;
  padding: 5px;
}


.input-field input[type="text"],
.input-field input[type="email"],
.input-field textarea {
  width: 100%;
  border: none;
  background-color: white;
  color: #0b0b0b; 
  margin-bottom: 10px; 
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #060606; 
}


.sub-mit{
  background-color: #05956ad7;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left:2%;
  width:95%;
  padding:2%;
  margin-bottom: 1%;

  // margin-right:10%
}

.close-button{
  background-color:#05956ad7;
  position: fixed;
  top:19%;
  left:63%;
  padding:0.6%;
  width:3%;
  border:none;
  border-radius:50%;
  color: white;
}

.remove-images{
  margin-left:2%;
  width:60%;
  padding:0.5%;
  border:none;
  border-radius:5px;
  background-color:#05956ad7;
  color:white;
  margin-bottom:1%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0rem;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.download-buttons {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  margin-top: 10px; 
  
  
}
.do-button {
  margin: 5px 0; 
  background-color:#05956ad7;
  color:white;
  border:none;
  width:70%;
  padding:1%;
  border-radius:5px;
}

@media (max-width: 768px) {
  .shopping {
    width: 300px;
    height: 300px;
    margin-left: 5%;
    margin-top: 20%;
  }

  .popup-content {
    width: 75%;
  }

  .down-load {
    width: 30%;
    margin-left: 35%;
  }

  .close-button {
    left: 82.6%;
    top:35%;
    
    
  }

  .shop-cart{
    margin-top:20%;
  }

 
  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:10%;
  }

  .grid-item {
    margin: 10px 0;
  }
  .remove-images{
    margin-left:2%;
    width:60%;
    padding:0.5%;
    border:none;
    border-radius:5px;
    background-color:#05956ad7;
    color:white;
    margin-bottom:15%;
  }
}







