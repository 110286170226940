

.image-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
    position: absolute;
    top:20%;
    left:15%;
 
  }
  
  .custom-card {
   
    border:solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .card-image {
   height:300px;
   width:300px;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  
  .tag {
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 20px;
    margin-right: 10px;
  }
  
  .back-button{
    position: absolute;
    top:14%;
    left:15%;
    background-color: #007bff;
    color:white;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 0.2%;
    padding-bottom: 0.2%;
    border: none;
    border-radius: 5px;

  }
  @media (max-width: 768px) {
    .card-container {
      gap: 30px;
      top: 15%;
      left: 3%;
      justify-content: center; 
      align-items: center; 
    }
  
    .custom-card {
      width: 300px;
      height:300px;
    }
  
    .card-image {
      height: 200px;
      width: 300px;
    }
  
    .tag {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  
  
  
  
  
  
  