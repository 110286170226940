    .update-home{
    position: fixed;
    left:66%;
    top:48%;
    border:none;
    border-radius:5px;
    background-color:#05956ad7 ;
    color: white;
    padding-left:8%;
    padding-right:6%;
    padding-top: 0.2%;
    padding-bottom: 0.2%;  
    }
    .image-url-home{
      height:500px;
      width:700px;
      margin-left:25%;
     
      border:  2px solid rgba(11, 177, 130, 0.5);
      padding: 10px;
    }
    .input-change-container-home{
      position: fixed;
      left:65%;
      color: white;
      top:30%;
      border: 2px solid rgba(11, 177, 130, 0.5);
      border-radius: 5px;
      padding:30px;
    }

    @media (max-width: 768px) {
        .update-home
        {
             top: 92%;
             left:20%;
             width:60%;
           }
           .input-change-container-home{
            left: 50%;
            width: 60%;
            transform: translate(-50%, 0);
            bottom:95%;
           }
    }