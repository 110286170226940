
.sign-up-body{
  display: flex;
    align-items: center;
    justify-content: center;
}
.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding-top:25px;
    padding-right:90px;
    padding-left:40px;
    padding-bottom:60px;
    border: none;
    border-radius: 5px;
    background-color: #141A31;
    position:fixed;
    top:17%;
  }
.form-login-sign{
  margin-left: 17%;
}

.group {
    margin-bottom: 15px;
}
.group{
    width: 120%;
    margin-right:30%;
    border-radius: 3px;
    font-size: 16px;
    outline: none;
    background-color: white;
    // color: black;
    
  }
  
.sign-up {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width:120%;
  }
  
  .sign-up:hover {
    background-color: #0056b3;
  }

  .or-p{
    margin-left:50%;
  }

  .login-button{
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width:120%;
   
  }

  .forgot-password{
    margin-left:3%;
    color: white;
  }

  @media (max-width: 768px) {
    .signup-container {
        width: 90%;
        padding: 20px;
        position: fixed;
        right:5%;
       padding-left:15%;
    }
  
    .group {
        margin-bottom: 10px;
        width:90%;
    }
  
    .group input {
        font-size: 14px;
    }
  
    .sign-up,
    .login-button {
        font-size: 14px;
        width:90%;
    }
    .or-p{
    margin-left:40%;

    }
  }


  
  