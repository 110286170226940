body.login{
 
  background-image: url("https://img.freepik.com/free-photo/abstract-digital-grid-black-background_53876-97647.jpg?w=1060&t=st=1693299865~exp=1693300465~hmac=c704720749cd398d1855bb0d09a9ef572eb95d23e97b01db49f398194a5fb105");
  background-size: cover;
  height: 100vh;
  margin: 0;
  padding: 0;
 
}
 
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  position: relative;
  // opacity:0.9;
 }

.login-box {
  background-color: #141A31;
  padding: 50px;
  border-radius: 2px;
  width: 400px;
  height: 400px;
  padding-bottom: 5px;
  padding-top: 10px; 
  padding-bottom:1px;
  position: absolute;
  bottom:0.5%;

}


.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2px;
}


.label-input-container .input {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  color: #000;
  width: 120%;
  height:95%;
  
  
}

h6{
  text-align:center;
}

.error {
  color: #ff0000;
  font-size: 14px;
  margin-right:41%;

}

.login-btn,.cust-login {
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  background: transparent;
  font-family: "Chakra Petch", sans-serif;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
 
  height: 45px;
  border: none;
 }
 .login-submit{
  border:2px solid #05956ad7;
 
  margin-top: 10%;
  text-align: center;


 }


//in this border when 
.checkbox-container {
  display: flex;
  align-items: center;
  color: white;
  margin-top: 7%;
  font-family:  "IBM Plex Mono", monospace;
  font-size:18px;

  
}

.checkbox-container input {
  margin-right: 5px;
  position: absolute;
  left:15%;
  
}


 .forgot-password-link {
  color: white;
  text-decoration: underline;
  //  margin-left: 10px;
   position:absolute;
   right:13%;
   font-size: 16px;
 
  } 

  .remember{
    font-size: 16px;
    position: absolute;
    left: 19%
  }

  label{
    font-size:16px;
    position: absolute;
    left:19%;
  }
//make this page responsive 
