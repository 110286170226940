.approval-buttons {
    margin-top: 20px;
    margin-left: 15px;
}

.approval {
    margin-right: 15px;
    background-color: #05956ad7;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    
}

.rejection {
    background-color: #05956ad7;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-left: 10px;
    
}

.container-approval {
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto;
    position: relative;
    margin-top: 10%;
    margin-left: 10%;
}

.search-bar {
    margin: 10px;
    margin-left: 300px;
    width: 60%;
    margin-top:10%;
    margin-bottom: 10%;
}

.search-bar .search {
    background-color: rgb(255, 255, 255);
    border: 2px solid black;
    border-radius: 5px;
}

.images-approve img {
    height: 300px;
    width: 400px;
    padding: 1px;
    object-fit: cover;
    padding: 10px;
}

.images-approve {
    border: 2px solid rgba(11, 177, 130, 0.5);
    padding: 10px;
    height: 95%;
    width: 60%;
    margin-bottom: 5%;
    object-fit: cover;
}

.description-fr-images {
    color: white;
}

.pagination {
    justify-content: center;
    margin: 0;
    padding: 6px;
}

.pages {
    border: 1px solid black;
    margin-right: 3px;
    padding: 4px;
    border-radius: 5px;
    background-color: blue;
    color: white;
    padding-right: 8px;
    padding-left: 8px;
}

@media (max-width: 768px) {
    .container-approval {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        width: 90%;
        margin-left: 30px;
        margin-bottom:10%;
    }
    .search-bar {
        margin-left: 5px;
        width: 90%;
        max-width: none;
        margin-top: 100px;
    }
    .pages {
        padding-right: 8px;
        padding-left: 8px;
    }
    .images-approve {
        border: 2px solid rgba(11, 177, 130, 0.5);
        padding: 10px;
        height: 95%;
        width: 80%;
       
    }

    
    .approval, .rejection {
        margin-bottom:40%;

    }
}
