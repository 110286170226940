
.container {
  display: grid;
  gap: 10px;
  grid: auto auto auto / auto auto auto ; 
  position: relative;
  margin-top:4%;
  margin-left:40%;
 
} 


.search-bar{
  margin:10%;
  margin-left:20%;
  width:60%;
 
}
.search-bar .search {
   background-color: rgb(255, 255, 255);
   border: 2px solid black;
   border-radius: 5px;
}
//now apply css on home page such that 
.images img{
    height:300px;
    width: 400px;
    padding:1px;
    object-fit: cover;
    border:2px solid rgba(11, 177, 130, 0.5);
    padding: 10px;
}

// .images{
  
//   border:2px solid rgba(11, 177, 130, 0.5);
//   padding: 10px;
//   height: 95%;
//   width:100%;
//   margin-bottom:5%;
 
// }


.pagination{
 justify-content: center;
 margin:0;
 padding:0.6%;
}

.pages{
  border:1px solid black;
  margin-right:0.3%;
  padding: 0.4%;
  border: none;
  border-radius: 5px;
  background-color: blue;
  color: white;
  padding-right:1%;
  padding-left: 1%;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
    // margin-bottom:10%;
  }
  .search-bar {
    margin-left: 5%;
    width: 90%;
    max-width: none; 
    margin-top:100px;
   }
   .pages{
         
    padding-right:4%;
    padding-left: 4%; 
   

  }
}
