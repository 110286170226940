/* Default styles for .edit-button */
.edit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 20px; /* Increase padding for both left and right sides */
  margin: 0 auto; /* Center the button horizontally */
  border-radius: 5px;
}


.edit-button:hover {
  background-color: #0056b3;
}

.edit-button:focus {
  outline: none;
}



.image-table-container {
  position: fixed;
  margin-top: 8%;
  margin-bottom: 15%;
  margin-left: 30%;
  overflow: scroll;
  max-height: 600px;
  overflow-x: hidden;

}


.image-table {
  max-width: 150%; 
  // width: 150%; 
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-header {
  background-color: white;
  text-align: left;
  padding: 12px;
  font-weight: bold;
  color: black;
  text-align: center;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-row {
  border-bottom: 1px solid #ddd;
}

.table-cell {
  text-align: center;
  padding: 12px;
  color: black;
}

.table-image {
 width: 100px; 
  height: 100px;
  object-fit: cover;
  border: 2px solid rgba(11, 177, 130, 0.5);
  padding: 2px;

}

@media screen and (max-width: 768px) {
 
  .image-table-container {
    margin-left: 0;
    margin-top: 25%;
    margin-left:1%;
    margin-right: 5%;
  }
  
  .image-table {
    margin-left: 0;
    box-shadow: none;
    max-width:150%;
  }
  
  .table-image {
    width: 90px;
    height: 60px; 
    margin-left: 0;
  }
  
  .table-cell {
    text-align: left; 
  }
}



