
.images-cart{
   
    height: 100vh;
    position: fixed;
    top:20%;
    
}


.image-url {
    height:500px;
    width:700px;
    margin-left:25%;
    border:  2px solid rgba(11, 177, 130, 0.5);
    padding: 10px;
 }
   
    .cart{
    position: fixed;
    left:72%;
    top:85%;
    border:none;
    border-radius:5px;
    background-color:#05956ad7 ;
    color: white;
    padding-left:3%;
    padding-right:3%;
    padding-top: 0.2%;
    padding-bottom: 0.2%;
    }

    .metadata-container{
       position: fixed;
        left:70%;
        color: white;
        top:30%;
        border: 2px solid rgba(11, 177, 130, 0.5);
        border-radius: 5px;
        padding:30px;
       }
       .metadata-item {
  border-bottom: 1px solid rgba(11, 177, 130, 0.5);

}

.metadata-text{
  background-color: #141A31;
  color:white;
  padding-top:2px;
  padding-bottom:2px;
  padding-left:2px;
  padding-right:2px;
  border:none;
  
  margin-top:1%;
  
  }
  

@media (max-width: 768px) {
  .images-cart {
    top: 10%;
  }

  .image-url {
    height: 150px;
    width: 200px;
    margin: 0 auto;
    margin-left: 40%;
  }

  .cart {
    left: 50%;
    top: 92%;
    transform: translateX(-50%);
    padding: 5px;
    width: 80%;
    font-size: 14px;
    
  }

  .metadata-container {
    left: 50%;
    top: 63%;
    transform: translate(-50%, -50%);
    width: 250px;
    padding: 20px;
  }

  .metadata-item {
    border-bottom: none;
  }
}
