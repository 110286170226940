.input-change-container{
    position: fixed;
    left:65%;
    color: white;
    top:30%;
    border: 2px solid rgba(11, 177, 130, 0.5);
    border-radius: 5px;
    padding:30px;
}

.description-input{
    margin-left:1%;
    margin-top:10%;
    width: 85%;
    padding-top: 2%;
    padding-bottom: 3%;
}

.tag-input{
    // margin-left:1%;
    // margin-top:10%;
    width: 85%;
    padding-top: 2%;
    padding-bottom: 3%;
}

.update{
    position: fixed;
    left:66%;
    top:60%;
    border:none;
    border-radius:5px;
    background-color:#05956ad7 ;
    color: white;
    padding-left:12%;
    padding-right:12%;
    padding-top: 0.2%;
    padding-bottom: 0.2%;
}

.edit-image-approve{
    position: fixed;
    left:66%;
    top:67%;
    border:none;
    border-radius:5px;
    background-color:#05956ad7 ;
    color: white;
    padding-left:12%;
    padding-right:12%;
    padding-top: 0.2%;
    padding-bottom: 0.2%;
}


@media (max-width: 768px) {
    .input-change-container {
      left: 50%;
      width: 60%;
      transform: translate(-50%, 0);
      top:60%;
    }
  
    .description-input,
    .tag-input,
    .update,
    .edit-image-approve {
      width: 100%;
    }
  
    .description-input,
    .tag-input {
      margin-top: 5%;
    }
  
    .update
 {
      top: 85%;
      left:20%;
      width:60%;
    }
    .edit-image-approve{
        top: 90%;
        left:20%;
        // padding: 2% 5%;
        width:60%;
    }
  }
  
